import React from 'react';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import theme from '@/style';
import Text from '@/components/basic/text';
import {toPriceStr} from '@/utils';

export function useShareToast() {
  const {i18n} = useTranslation();
  const shareSuccess = (shareAward: number) =>
    globalStore.globalSucessTotal(
      i18n.t('bets-share.label.shareSuccess'),
      shareAward ? (
        <View style={[theme.margin.tops]}>
          <Text color={theme.basicColor.white} size="medium" textAlign="center">
            {i18n.t('bets-share.label.receiving')}
          </Text>
          <Text textAlign="center" size="medium" color={theme.basicColor.white}>
            <Text
              fontFamily="fontInterBold"
              color={theme.backgroundColor.second}
              size="medium">
              {toPriceStr(1, {
                fixed: 0,
                showCurrency: true,
              })}
            </Text>{' '}
            {i18n.t('bets-share.label.reward')}
          </Text>
        </View>
      ) : (
        <Text>''</Text>
      ),
    );
  const shareFail = () =>
    globalStore.globalWaringTotal(
      i18n.t('bets-share.label.shareFail'),
      <View style={[theme.margin.tops]}>
        <Text color={theme.basicColor.white} size="medium" textAlign="center">
          {i18n.t('bets-share.label.check')}
        </Text>
        <Text textAlign="center" size="medium" color={theme.basicColor.white}>
          {i18n.t('bets-share.label.connection')}
        </Text>
      </View>,
    );

  const agreeTip = () =>
    globalStore.globalWaringTotal(
      i18n.t('bets-share.label.friendlyReminder'),
      <View style={[theme.margin.tops]}>
        <Text color={theme.basicColor.white} size="medium" textAlign="center">
          {i18n.t('bets-share.label.frTip')}
        </Text>
        <Text textAlign="center" fontSize={14} size="medium" color={'#F15802'}>
          {i18n.t('bets-share.label.frContent')}
        </Text>
      </View>,
    );

  return {
    shareSuccess,
    shareFail,
    agreeTip,
  };
}
